import axios from "axios";
import connect from "./axios";

const zonesServices = {
  getOne: async (id) => {
    let response = [];
    await connect
      .get(`/zones/${id}.json`)
      .then((res) => {
        response.push(res.data);
      })
      .catch((err) => console.log(err));
    return response;
  },
  getAll: async () => {
    let response = [];
    await axios
    /*   .get("/zones.json") */
      .get("/get-zones") 
      .then((res) => {
        response.push(res.data);
      })
      .catch((err) => console.log(err));
    return response;
  },
};
export default zonesServices;
