import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useConfigsContext } from "../../../../../../context/configsContextProvider";

const DeliverySelectDaysContainer = ({
  answerPostalCode,
  showFormErrors,
  deliveryDay,
  setDeliveryDay,
  clientZone,
  disabled
}) => {
  // const { configs } = useConfigsContext();

  const [daysState, setDays] = useState([]);
  useEffect(() => {
    
    const daysMapped = clientZone
    // ?.map((zone) => zone.deliveryDay?.match(/\/api\/configs\/(.+)$/)[1])
    ?.map((zone) => zone.deliveryDay?.value)
    // .map((config) => configs[config]?.value)

    setDays(Array.from(new Set(daysMapped)));
  }, [clientZone]);

  const handleDeliveryDay = (e) => setDeliveryDay(e.target.value);

  return (
    <div className="col-md-6">
      <FormControl variant="standard" className="form-control_delivery-select">
        <InputLabel id="demo-simple-select-standard-label">
          Elegí tu día de entrega*
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={deliveryDay}
          onChange={(e) => handleDeliveryDay(e)}
          label="Elegí una zona*"
          disabled={disabled}
        >
          <MenuItem value="elegi" hidden>
            <em>Elegí tu día de entrega*</em>
          </MenuItem>
          {daysState?.map((day, index) => (
            <MenuItem key={index} value={day}>
              {day}
            </MenuItem>
          ))}
        </Select>
        {showFormErrors && !deliveryDay && (
          <small className="text-danger">Campo requerido</small>
        )}
      </FormControl>
    </div>
  );
};

DeliverySelectDaysContainer.propTypes = {
  answerPostalCode: PropTypes.bool,
  showFormErrors: PropTypes.bool,
  deliveryDay: PropTypes.string,
  setDeliveryDay: PropTypes.func,
};

export default DeliverySelectDaysContainer;
